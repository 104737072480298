import React, { Component, Fragment } from 'react'
import { Grid, Paper, Typography, Icon, FormControlLabel, Container } from '@material-ui/core'
import String from '../common/String';
import Email from '../common/Email';
import Number from '../common/Number';
import SimpleSelect from '../common/SimpleSelect';
import DateValid from '../common/DateValid';

export default class RegretFormConfirmation extends Component {

    constructor(props) {
        super(props);

    }

    render() {
        const pasoUno = this.props.infoStep.filter(e => e.step === 1)[0]
        const pasoDos = this.props.infoStep.filter(e => e.step === 2)[0]
        return (
            <Fragment>
                <Container maxWidth="xl" disableGutters={true} className="topInfoBrand">
                    <Grid container spacing={0}>
                        <Grid item sm={12}>
                            <Typography component="div"><Icon className="headerIcon">check_circle_outline</Icon></Typography>
                            <Typography component="div" variant="h1" className="headerTitle">Tu consulta fue enviada de manera exitosa!</Typography>
                            <Typography component="div" variant="h3" className="headerParagraph ">Te estaremos actualizando sobre tu solicitud únicamente vía correo electrónico, no olvides chequear la bandeja de spam y correo no deseado.<br />Muchas gracias!</Typography>
                        </Grid>
                    </Grid>
                </Container>
                <br /> <br />
                <Container className="formBlock">
                    <Paper className="paper paper-form">
                        <Grid container spacing={2} >
                            <Grid item md={12} className="formContent">
                                <Grid container spacing={1}>
                                    <Grid item md={12} >
                                        <Typography component="span" variant="h4">Tu código de gestión es: </Typography> <Typography component="span" variant="h1"> {pasoDos.responseWs.data.number_claim} </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Container>
                <Container className="formBlock">
                    <Paper className="paper paper-form">
                        <Grid container spacing={2} >
                            <Grid item md={12} className="formContent">
                                <Grid container spacing={1}>
                                    <Grid item md={12}>
                                        <Typography variant="h4">Detalle de tu compra</Typography>
                                    </Grid>
                                    <Grid item sm={3}>
                                        <div className="dataInfo">
                                            <div className="dataTitle">Tipo de producto</div>
                                            <div className="dataDescription">{pasoUno.info.tipoproducto}</div>

                                        </div>
                                    </Grid>
                                    <Grid item sm={3}>
                                        <div className="dataInfo">
                                            <div className="dataTitle">Nro de compra</div>
                                            <div className="dataDescription"> <span className="textColor">{pasoUno.responseWs.request.purchase_number.split("-")[0]}</span> - {pasoUno.responseWs.request.purchase_number.split("-")[1]} </div>
                                        </div>
                                    </Grid>
                                    <Grid item sm={3}>
                                        <div className="dataInfo">
                                            <div className="dataTitle">Fecha de compra</div>
                                            <div className="dataDescription"><Icon className="iconColor">event</Icon>{`${pasoUno.responseWs.data.reservation_date.split("-")[2]}/${pasoUno.responseWs.data.reservation_date.split("-")[1]}/${pasoUno.responseWs.data.reservation_date.split("-")[0]}`}</div>
                                        </div>
                                    </Grid>
                                    <Grid item sm={3}>
                                        <div className="dataInfo">
                                            <div className="dataTitle">Fecha de inicio/salida del servicio</div>
                                            <div className="dataDescription"><Icon className="iconColor">event</Icon> {`${pasoUno.responseWs.data.service_date.split("-")[2]}/${pasoUno.responseWs.data.service_date.split("-")[1]}/${pasoUno.responseWs.data.service_date.split("-")[0]}`}</div>
                                        </div>
                                    </Grid>
                                    <Grid item sm={3}>
                                        <div className="dataInfo">
                                            <div className="dataTitle">Destino</div>
                                            <div className="dataDescription">{pasoUno.responseWs.data.destination}</div>
                                        </div>
                                    </Grid>
                                    <Grid item md={12}>
                                        <Typography variant="h4">Datos del titular</Typography>
                                    </Grid>
                                    <Grid item sm={3}>
                                        <div className="dataInfo">
                                            <div className="dataTitle">Nombre y Apellido</div>
                                            <div className="dataDescription">{pasoDos.info.pax_name} {pasoDos.info.pax_surname}</div>
                                        </div>
                                    </Grid>
                                    <Grid item sm={3}>
                                        <div className="dataInfo">
                                            <div className="dataTitle">Teléfono de contacto</div>
                                            <div className="dataDescription">{pasoDos.info.contact_phone}</div>
                                        </div>
                                    </Grid>
                                    <Grid item sm={3}>
                                        <div className="dataInfo">
                                            <div className="dataTitle">Horario de contacto</div>
                                            <div className="dataDescription">{pasoDos.info.contact_hour}</div>
                                        </div>
                                    </Grid>
                                    <Grid item sm={12}>
                                        <div className="dataInfo">
                                            <div className="dataTitle">Tu consulta</div>
                                            <div className="dataDescription"> {pasoDos.info.observations}</div>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Container>
            </Fragment>
        )
    }
}
