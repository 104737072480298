import React, { Component, Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Container, Card, Stepper, Step, StepLabel, Button, Typography, Dialog, DialogContent } from '@material-ui/core';
import FormSales from './layout/FormSales'
import PreloadModal from './common/PreloadModal';
import { connect } from "react-redux";
import { Redirect } from 'react-router-dom';

const useStyles = theme => ({
	root: {
		width: '100%',
	},
	button: {
		marginRight: theme.spacing(1),
	},
	instructions: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
});


class StepsSales extends Component {
	constructor(props) {
		super(props);
		this.state = {
			activeStep: 0,
			modalOpen: false,
			skipped: new Set(),
			cliente: this.props.client
		}
		this.steps = ['Datos del titular', 'Confirmación'];

	}

	UNSAFE_componentWillReceiveProps(nextProps) {

		if (nextProps.status === "SUCCESS") {
			this.setState({ activeStep: this.state.activeStep + 1 })
		} else {

			console.log("NO PUEDE AVANZAR", this.props.status);
		}
	}

	getStepContent(step) {
		switch (step) {
			case 0:
				return (<FormSales client={this.state.cliente} ref={element => { this.component = element }}  {...this.props} />);
			default:
				return (<p></p>);
		}
	}

	isStepSkipped = (step) => {
		return this.state.skipped.has(step);
	};

	UNSAFE_componentWillReceiveProps(nextProps) {

		if (nextProps.status === "SUCCESS") {
			console.log("SUCCESS")
			this.setState({ activeStep: this.state.activeStep + 1 })
		} else {

			console.log("NO PUEDE AVANZAR", this.props.status);
		}
	}

	handleNext = () => {

		const responseChilComponent = this.component.handleSubmit();

		if (responseChilComponent.status) {
			//this.props.updatePreloadSales(true);
			this.props.updatePreload(true);

			let newSkipped = this.state.skipped;
			if (this.isStepSkipped(this.state.activeStep)) {
				newSkipped = new Set(newSkipped.values());
				newSkipped.delete(this.state.activeStep);
			}

			this.props.sendFormSales(this.props.infoStep, responseChilComponent.data, this.state.activeStep + 1)

		}

	};

	handleBack = () => {
		this.setState({ activeStep: this.state.activeStep - 1 })

	};

	handleSkip = () => {
		this.setState((prevState) => {

			const newSkipped = new Set(prevState.skipped.values());
			newSkipped.add(this.state.activeStep);
			let response = {
				activeStep: prevState.activeStep + 1,
				skipped: newSkipped
			}
			return response;
		})
	};

	handleReset = () => {
		this.setState({ activeStep: 0 })
	};

	render() {
		const { classes, client } = this.props;
		return (
			<Fragment>
				<PreloadModal fullWidth maxWidth='xl' id="cardProductDialog" size="large"
					open={this.props.preload} img={`${client.name}/preload-booking.gif`}
				/>

				<div className={classes.root} >
					<Stepper activeStep={this.state.activeStep}>
						{this.steps.map((label, index) => {
							const stepProps = {};
							const labelProps = {};
							if (this.isStepSkipped(index)) {
								stepProps.completed = false;
							}
							return (
								<Step key={label} {...stepProps}>
									<StepLabel {...labelProps}>{label}</StepLabel>
								</Step>
							);
						})}
					</Stepper>
					<div>
						{Object.keys(this.props.infoStep).length === 1 ? (
							<Redirect to={
								{
									pathname: '/confirmacion-ventas',
									state: { infoStep: this.props.infoStep }
								}}
							/>
						) : (
								<Container >
									<Grid container spacing={2} >
										<Grid item md={12}>
											<div>
												<div className={classes.instructions}>{this.getStepContent(this.state.activeStep)}</div>
												<div>
													{/* <Button disabled={this.state.activeStep === 0} onClick={this.handleBack} className={classes.button}>Anterior</Button> */}
													<Button
														variant="contained"
														color="primary"
														onClick={this.handleNext}
														className={classes.button}
													>
														{this.state.activeStep === this.steps.length - 1 ? 'Finalizar' : 'Siguiente'}
													</Button>
												</div>
											</div>
										</Grid>
									</Grid>
								</Container>
							)}
					</div>
				</div>
			</Fragment>
		);
	}
}
const mapStateToProps = state => {
	return state;
};

const mapDispatchToProps = dispatch => {
	return {
		sendFormSales: (lastStep, currentStep, currentNumberStep) => dispatch({ type: "SEND_FORM_SALES", lastStep, currentStep, currentNumberStep }),
		//updatePreloadSales: (boolean) => { dispatch({ type: "UPDATE_PRELOAD_SALES", boolean }) }
		updatePreload: (boolean) => { dispatch({ type: "UPDATE_PRELOAD", boolean }) }
	};
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
	null,
	{ forwardRef: true }
)(withStyles(useStyles)(StepsSales))