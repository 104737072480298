import React, { Component, Fragment } from 'react';
import { Grid, Container, Paper } from '@material-ui/core';
import Header from "./Header";
import Footer from "./Footer";
import Theme from "../assets/Theme";
import config from ".././config/config.json";
import TopInfoBrand from "./layout/TopInfoBrand";
import StepsSales from "./StepsSales";

export default class LayoutRegret extends Component {
    render() {

        return (
            <Fragment>
                <Theme>
                    <div className={`wrapper`} styles={config.styles} >
                        <Header client={config.client} />
                        <div className="main">
                            <TopInfoBrand />
                            <StepsSales client={config.client} />
                        </div>
                        <Footer client={config.client} />
                    </div>

                </Theme>
            </Fragment>
        )
    }
}
