import { select } from "redux-saga/effects";

const initialState = {
    infoStep: [],
    responseContactType: [],
    status: null,
    preload: false,
    responseApiModulos: [{ id: '1', code: 'Aereo', description: 'Aereo', 'module': 'VL' }]
    /* "responseWs": {
         message: null
     }*/
};

const reducer = (state = initialState, action) => {
    const newState = { ...state };

    switch (action.type) {

        case "RESPONSE_CONTACT_TYPE":

            newState.responseContactType = action.value;

            break;

        case "RESPONSE_MODULES":

            newState.responseApiModulos = action.value;

            break;

        case "RESPONSE_FORM":

            newState.status = action.response.status;
            newState.preload = false;
            if (action.response.status === "SUCCESS") {
                newState.infoStep = [...newState.infoStep, { "step": action.step, "info": action.infoStep, "responseWs": action.response }];
            } else {
                newState.responseWs = action.response;
            }

            break;

        case "RESPONSE_FORM_SALES":
            console.log('RESPONSE_FORM_SALES', action.response.status);
            // newState.status = "SUCCESS";
            newState.preload = false;
            if (action.response.status !== "ERROR") {

                newState.infoStep = [...newState.infoStep, { "step": action.step, "info": action.infoStep, "responseWs": action.response }];
            } else {
                newState.status = "ERROR";
                newState.responseWs = action.response;
            }

            break;


        case "PRELOAD":

            newState.preload = true;
            break;
    }

    return newState;
};

export default reducer;