import { all, cancel } from 'redux-saga/effects';
import { getContactType, sendForm, updatePreload } from "./saga";
import { sendFormSales, updatePreloadSales } from "./sagaSales";


export default function* rootSaga() {
    yield all([
        getContactType(),
        sendForm(),
        updatePreload(),
        updatePreloadSales(),
        sendFormSales()
    ])
}