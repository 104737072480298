import React, {Fragment} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {AppBar, Container, Toolbar, Link, Chip, Icon, Hidden} from '@material-ui/core';
import Image from './layout/Image';
import BackgroundBnaViajes from './../assets/images/bna/bg-white.png';
import BackgroundTiendaBna from './../assets/images/bna/bg-darkblue.png';
import BackgroundBnaModa from './../assets/images/bna/bg-orange.png';

const useStyles = makeStyles(theme => ({
    site_header: {
        minHeight: 'auto',
        '& img' : {
            [theme.breakpoints.down('sm')]: {
                maxHeight: '40px'
            }
        }
    },
    navbarItems: {
        display: 'flex'
    }
}))

export default function Header(props) {
    const classes = useStyles();
    const {client, userLogin } = props

    return (
        <Fragment>
            <AppBar position="relative">
                <Container>
                    {userLogin && userLogin !== undefined && userLogin !== "" &&
                        <Chip variant="outlined"
                            className="name_user"
                            icon={<Icon> account_circle </Icon>}
                            label={<span>Hola, <span className="name">{userLogin}</span></span> }
                        />
                    }

                    <Toolbar className={classes.site_header}>
                        <div className={classes.navbarItems }>
                            <Link href={client.siteUrl} target="_blank" className='item-navitation bna-viajes' 
                                style={{ backgroundImage: `url(${BackgroundBnaViajes})`, }}
                                
                                
                                >
                                <Image img={`${client.name}/logo-bnaviajes.svg`} alt="BNA Viajes" className={classes.logo} />
                            </Link>
                            <Link href="https://www.tiendabna.com.ar/" target="_blank" className='item-navitation tienda-bna'
                            style={{ backgroundImage: `url(${BackgroundTiendaBna})`, }}
                            >

                                <Hidden mdUp>
                                    <Image img={`${client.name}/icon-tiendabna.svg`} alt="Tienda BNA" className={classes.logoTiendaBna} />
                                </Hidden>
                                <Hidden smDown>
                                    <Image img={`${client.name}/logo-tiendabna.svg`} alt="Tienda BNA" className={classes.logoTiendaBna} />
                                </Hidden>                               
                                
                            </Link>
                            <Link href="https://www.tiendabna.com.ar/" target="_blank" className='item-navitation bna-moda'
                            style={{ backgroundImage: `url(${BackgroundBnaModa})`, }}
                            >
                                <Hidden mdUp>
                                    <Image img={`${client.name}/icon-bnamoda.svg`} alt="BNA Moda" className={classes.logoBnaModa} />
                                </Hidden>
                                <Hidden smDown>
                                    <Image img={`${client.name}/logo-bnamoda.svg`} alt="BNA Moda" className={classes.logoBnaModa} />
                                </Hidden>  
                                
                                
                            </Link>
                        </div>
                        <div className="logo-tiendabna">
                            <Image img={`${client.name}/logotypo-tiendabna.svg`}  alt="Tienda BNA" className={classes.logotypoTiendaBna} />
                        </div>
                    </Toolbar>
                </Container>
            </AppBar>
        </Fragment>
    )
}
