import React, {Component, Fragment} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

export default class SimpleSelect extends Component {

  	constructor(props){
		super(props);

		this.state={
			defaultValue:this.props.defaultValue,
			//value:this.props.defaultValue,
			focus: false
		}
		this.onFocus = this.onFocus.bind(this);
        this.onBlur = this.onBlur.bind(this);
	}

  	handleChange = event => {
		this.setState({defaultValue:event.target.value});
  	};

	onBlur() {setTimeout(() => {if (this.state.focus){this.setState({focus: false})}}, 0)}
    onFocus() {if (!this.state.focus) {this.setState({focus: true})}}

  	render(){
		const classes = makeStyles(theme => ({
			formControl: {"& .MuiPaper-root": {maxHeight: '340px'}}
		}));
		const OnFocusHideFixedElements = withStyles({"@global": {
    "body .headroom":{'@media (max-width: 960px)': {transform: "translate3d(0px, -100%, 0px)!important"}}
}})(() => null);
		const {id, labelId, labelText, optionsSelect, /* defaultValue, */ name} = this.props;

    return (
		<Fragment>
			{this.state.focus && <OnFocusHideFixedElements/>}
			<FormControl variant="outlined" fullWidth  className={classes.formControl} >
				<InputLabel id={labelId}>{labelText}</InputLabel>
				<Select
					labelId={labelId}
					id={id}
					name={name}
					onChange={this.handleChange}
					label={labelText}
					value={this.state.defaultValue}
					disabled={this.props.disabled}
					className={this.props.classNameCustom}
					onFocus={this.onFocus}
                	onBlur={this.onBlur}
					>
					{optionsSelect.map((option, i) => (
						<MenuItem key={i} value={option.code}>{option.description}</MenuItem>
					))}
				</Select>
			</FormControl>
	  </Fragment>
    )
  }
}
