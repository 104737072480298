import React, {Fragment} from 'react';
import './App.scss';
import Routing from './Routing'
import CssBaseline from '@material-ui/core/CssBaseline';

function App() {
  return (
    <Fragment>
      <CssBaseline />
      <Routing />
    </Fragment>
  );
}

export default App;
