import React, { Component, Fragment } from 'react';
import { Grid, Paper, Typography, Container, FormControl, Select, MenuItem } from '@material-ui/core'
import Header from "./Header";
import HeaderBNA from "./HeaderBNA";
import Footer from "./Footer";
import Theme from "../assets/Theme";
import config from ".././config/config.json";
import TopInfoBrand from "./layout/TopInfoBrand";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom'


class LayoutLandinForms extends Component {

    constructor(props) {
        super(props);

        this.state = {
            getType: null,
        }
    }

    UNSAFE_componentWillReceiveProps(next_props) {
        /*  var temporaryObj = [];
          next_props.responseApiModulos.map((modules) => {
              temporaryObj.push({ id: modules.id, code: modules.module, description: modules.module, 'module': modules.code })
          })*/

        this.setState({ getType: true })

    }
    UNSAFE_componentWillMount() {
        this.props.getContactType('fkKv5jehJSGWBcdxAoVB5TEaWGtMVAQVnZGoGnCA');

    }
    handleTypeReason = (event) => {
        const { location, history } = this.props
        let path = this.props.responseContactType.filter(type => type.id === event.target.value);
        path = path[0].motivo.toLowerCase()
        history.push('/formulario/' + path + '/' + event.target.value);
    }

    render() {
        let responseContactType = this.props.responseContactType
        console.log(responseContactType)
        return (
            <Fragment>
                <Theme>
                    <div className={`wrapper site-${config.client.name}`} styles={config.styles} >

                        {config.client.name === 'bna' ?  <HeaderBNA client={config.client} /> :  <Header client={config.client} /> }
                        
                        <div className="main">
                            <TopInfoBrand />
                            <Container >
                                <Grid container spacing={2} >
                                    <Grid item md={12}>
                                        <div className="introStep">
                                            <Typography variant="h1" className="headerTitle" color="primary">¿Cómo podemos ayudarte?</Typography>
                                            <Typography variant="h4" className="headerParagraph paragraphMargin">Para poder brindarte una mejor asistencia, por favor seleccioná
                                    el motivo por el cual nos estás contactando.</Typography>
                                        </div>

                                    </Grid>
                                    <Grid item md={12}>
                                        <div className="stepHeader">
                                            <Typography variant="h3" color="primary" className="headerTitle">1. Motivo de contacto</Typography>
                                            <Paper className="paper paper-form">
                                                <FormControl variant="outlined" fullWidth >
                                                    {
                                                        this.state.getType !== null ?

                                                            <Select
                                                                //ref={element => { this.state.tipoproducto = element }}
                                                                labelId="tipoproducto"
                                                                id="tipoproducto"
                                                                name={`motivo`}
                                                                // defaultValue={"Aereo"}
                                                                onChange={this.handleTypeReason}
                                                                label="Tipo de Producto"
                                                            >
                                                                {
                                                                    responseContactType.map((contactType, indice) => {
                                                                        return <MenuItem key={indice} value={contactType.id}>{contactType.name}</MenuItem>
                                                                    })
                                                                }
                                                            </Select>
                                                            :

                                                            < p > cargando...</p>

                                                    }
                                                </FormControl>
                                            </Paper>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Container>
                        </div>
                        
                        <Footer client={config.client} />
                    </div>

                </Theme>
            </Fragment>
        )
    }
}

const mapStateToProps = state => {
    return state
};

const mapDispachToProps = dispatch => {
    return {
        getContactType: (access) => dispatch({ type: "GET_CONTACT_TYPE", access }),
        //  getModules: () => dispatch({ type: "GET_MODULES" }),
    };
};


export default withRouter(connect(mapStateToProps, mapDispachToProps, null,
    { forwardRef: true })(LayoutLandinForms))



