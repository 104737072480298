import React , { Component, Fragment } from 'react';
import { TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

export default class StringNumber extends Component{
    constructor(props){
        super(props);
        this.state={
            errorText: '',
            error:props.error,
            defaultValue:this.props.defaultValue,
            focus: false
        };
        this.handleChange = this.handleChange.bind(this);
        this.onFocus = this.onFocus.bind(this);
        this.onBlur = this.onBlur.bind(this);
    }
    UNSAFE_componentWillReceiveProps(next_props) {
        this.setState({ error: next_props.error });
    }
    handleChange = (event) =>{
        this.setState({defaultValue:event.target.value});

        if(event.target.value === ''){
            this.setState({errorText: this.props.placeholder,error:true});
        }else{
            this.setState({errorText: '',error:false});
        }
    }

    onBlur() {setTimeout(() => {if (this.state.focus){this.setState({focus: false})}}, 0)}
    onFocus() {if (!this.state.focus) {this.setState({focus: true})}}

  render() {

    const OnFocusHideFixedElements = withStyles({"@global": {
    "body .headroom":{'@media (max-width: 960px)': {transform: "translate3d(0px, -100%, 0px)!important"}}
}})(() => null);  

    return (
        <Fragment>
            {this.state.focus && <OnFocusHideFixedElements/>}
            <TextField 
                fullWidth
                InputLabelProps={{shrink: true}}
                className={this.props.classNameCustom}
                variant="outlined" 
                error={this.state.error}
                id={this.props.id} 
                label={this.props.label} 
                placeholder={this.props.placeholder}
                helperText={this.state.errorText}
                onChange={this.handleChange}
                value={this.state.defaultValue}
                name={this.props.name}
                disabled={this.props.disabled}
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                rows={this.props.rows}
                multiline={this.props.multiline}
    />
        </Fragment>
    );
  };
};


