import React, { Component, Fragment } from 'react'
import { Grid, Paper, Typography, Icon, FormControlLabel, Container } from '@material-ui/core'
import String from '../common/String';
import StringNumber from '../common/StringNumber';
import Email from '../common/Email';
import Number from '../common/Number';
import SimpleSelect from '../common/SimpleSelect';
import DateValid from '../common/DateValid';
import { Alert, AlertTitle } from '@material-ui/lab';
import { withRouter } from "react-router-dom";
import config from '../../config/config_textos.json';

const withRouterAndRef = (WrappedComponent) => {
    class InnerComponentWithRef extends React.Component {
        render() {
            const { forwardRef, ...rest } = this.props;
            return <WrappedComponent {...rest} ref={forwardRef} />;
        }
    }
    const ComponentWithRouter = withRouter(InnerComponentWithRef, { withRef: true });
    return React.forwardRef((props, ref) => {
        return <ComponentWithRouter {...props} forwardRef={ref} />;
    });
}

class FormSales extends Component {

    constructor(props) {
        super(props);

        this.state = {
            pax_name: '',
            pax_surname: '',
            contact_phone: '',
            contact_hour: '',
            observations: '',
            responseForgot: false,
            getHorarios: [
                { code: '9 a 14', description: '9 a 14' },
                { code: '14 a 18', description: '14 a 18' }
            ],
            errors: {
                pax_name: false,
                pax_surname: false,
                contact_phone: false,
                contact_hour: false,
                observations: false,
            },
            showErrorText: {
                horario: false
            }
        }
        console.log(this.props)
        //  this.dataPasoUno = this.props.infoStep.filter(e => e.step === 1)[0];

    }
    UNSAFE_componentWillReceiveProps(next_props) {
        //  this.dataPasoUno = next_props.infoStep.filter(e => e.step === 1)[0];
    }

    handleSubmit = () => {
        let count = 0;
        let countValid = 0;
        let form = new Object();
        let response = true;

        if (this.state.pax_name && this.state.pax_name !== null) {
            count++;
            if (this.state.pax_name.state.defaultValue !== '') {
                countValid++;
                form[this.state.pax_name.props.name] = this.state.pax_name.state.defaultValue;
            }
        }
        if (this.state.pax_surname && this.state.pax_surname !== null) {
            count++;
            if (this.state.pax_surname.state.defaultValue !== '') {
                countValid++;
                form[this.state.pax_surname.props.name] = this.state.pax_surname.state.defaultValue;
            }
        }
        if (this.state.contact_phone && this.state.contact_phone !== null) {
            count++;
            if (this.state.contact_phone.state.defaultValue !== '') {
                countValid++;
                form[this.state.contact_phone.props.name] = this.state.contact_phone.state.defaultValue;
            }
        }

        if (this.state.contact_hour && this.state.contact_hour !== null) {
            count++;
            if (this.state.contact_hour.state.defaultValue !== '' && this.state.contact_hour.state.defaultValue !== null) {
                countValid++;
                form[this.state.contact_hour.props.name] = this.state.contact_hour.state.defaultValue;
            }
        }
        if (this.state.email && this.state.email !== null) {
            count++;
            if (this.state.email.state.defaultValue !== '') {
                countValid++;
                form["purchase_mail"] = this.state.email.state.defaultValue;
            }
        }
        if (this.state.observations && this.state.observations !== null) {
            count++;
            if (this.state.observations.state.defaultValue !== '') {
                countValid++;
                form[this.state.observations.props.name] = this.state.observations.state.defaultValue;
            }
        }

        this.setState({
            errors: {
                pax_name: (this.state.pax_name && this.state.pax_name.state.defaultValue !== '' && this.state.pax_name.state.errorText === '' && this.state.pax_name.state.errorText === '') ? false : true,
                pax_surname: (this.state.pax_surname && this.state.pax_surname.state.defaultValue !== '' && this.state.pax_surname.state.errorText === '' && this.state.pax_surname.state.errorText === '') ? false : true,
                contact_phone: (this.state.contact_phone && this.state.contact_phone.state.defaultValue !== '' && this.state.contact_phone.state.errorText === '' && this.state.contact_phone.state.errorText === '') ? false : true,
                contact_hour: (this.state.contact_hour && this.state.contact_hour.state.defaultValue !== '' && this.state.contact_hour.state.errorText === '' && this.state.contact_hour.state.errorText === '') ? false : true,
                observations: (this.state.observations && this.state.observations.state.defaultValue !== '' && this.state.observations.state.errorText === '') ? false : true,
                email: (this.state.email && this.state.email.state.defaultValue !== '' && this.state.email.state.errorText === '') ? false : true

            },
            showErrorText: {
                horario: (this.state.horario && this.state.horario.state.defaultValue === '') ? true : false
            }
        })

        if (response) {
            if (countValid !== count) {
                response = false;
            }
        }
        // form["reason"] = 10;

        form["module"] = null;
        form["purchase_number"] = null;
        form["reservation_date"] = null;
        form["service_date"] = null;

        form["reason"] = this.props.match.params.idInquiries
        return { 'status': response, 'data': form };
    }

    render() {
        //   console.log(this.dataPasoUno)
        return (
            <Fragment>
                <Container >
                    <Grid container spacing={2} >
                        <Grid item md={12}>
                            <div className="stepHeader">
                                <Typography variant="h3" color="primary" className="headerTitle">2. Datos del titular</Typography>
                                <Typography variant="h4" className="headerSubtitle paragraphMargin">Ingresá los datos del titular de la reserva</Typography>
                            </div>
                        </Grid>
                    </Grid>
                </Container>

                <Container className="formBlock">
                    <Paper className="paper paper-form">
                        <Grid container spacing={2} >
                            <Grid item xs={12} md={12}>
                                {this.props.status === "ERROR" && (
                                    <Alert severity="error">
                                        <Typography>{this.props.responseWs.message}</Typography>
                                    </Alert>
                                )}
                            </Grid>
                            <Grid item md={8} className="formContent">
                                <Grid container spacing={1}>
                                    <Grid item md={12}>
                                        <Typography variant="h4">Datos de contacto</Typography> <br />
                                    </Grid>
                                    <Grid item sm={12}>
                                        {!this.state.responseForgot === false && (
                                            <Alert severity="error">
                                                <Typography>Debe completar todos los campos.</Typography>
                                            </Alert>
                                        )}
                                    </Grid>
                                    <Grid item sm={6} xs={12}>

                                        <String
                                            ref={element => { this.state.pax_name = element }}
                                            id={`pax_name`}
                                            label="Nombre"
                                            name={`pax_name`}
                                            placeholder=""
                                            defaultValue={''}
                                            disabled={this.props.disabled ? this.props.disabled : false}
                                            error={this.state.errors.pax_name}
                                        />
                                    </Grid>
                                    <Grid item sm={6} xs={12}>

                                        <String
                                            ref={element => { this.state.pax_surname = element }}
                                            id={`pax_surname`}
                                            label="Apellido"
                                            name={`pax_surname`}
                                            placeholder=""
                                            defaultValue={''}
                                            disabled={this.props.disabled ? this.props.disabled : false}
                                            error={this.state.errors.pax_surname}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Email
                                            ref={element => { this.state.email = element }}
                                            id="email"
                                            label="Email"
                                            name="email"
                                            defaultValue={''}
                                            error={this.state.errors.email}
                                            autoComplete="email"
                                        />
                                    </Grid>

                                    <Grid item sm={5} xs={12}>
                                        <Number
                                            ref={element => { this.state.contact_phone = element }}
                                            id={`contact_phone`}
                                            label={"Teléfono de contacto " + config.ventas.telefono}
                                            name={`contact_phone`}
                                            placeholder=""
                                            minLength={null}
                                            maxLength={null}
                                            defaultValue={''}
                                            disabled={this.props.disabled ? this.props.disabled : false}
                                            error={this.state.errors.contact_phone}
                                        />
                                    </Grid>

                                    <Grid item sm={7} xs={12}>
                                        <SimpleSelect
                                            ref={element => { this.state.contact_hour = element }}
                                            id={`contact_hour`}
                                            labelId={`contact_hour`}
                                            labelText="Horario de preferencia:"
                                            name={`contact_hour`}
                                            defaultValue={'9 a 14'}
                                            classNameCustom={this.state.showErrorText.contact_hour ? 'invalidData' : 'validData'}
                                            optionsSelect={this.state.getHorarios}
                                            disabled={this.props.disabled ? this.props.disabled : false}
                                        />
                                    </Grid>
                                    <Grid item sm={12} xs={12}>
                                        <Typography variant="h4">Tu consulta</Typography> <br />
                                    </Grid>
                                    <Grid item sm={12} xs={12}>
                                        <StringNumber
                                            ref={element => { this.state.observations = element }}
                                            id={`observations`}
                                            label={config.ventas.comentarios}
                                            name={`observations`}
                                            placeholder=""
                                            defaultValue={''}
                                            disabled={this.props.disabled ? this.props.disabled : false}
                                            error={this.state.errors.observations}
                                            multiline={true}
                                            rows="5"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Container>
            </Fragment>
        )
    }
}
export default withRouterAndRef(FormSales)