import { takeLatest, takeEvery, put, call } from "redux-saga/effects";
import apiCall from "../services"
import config from '../config/config.json';

function* contactType(actions) {
    try {

        const headers = {
            'Content-Type': "application/json",
            'token': config.credentials.token
        };

        const requestOptions = {
            headers: headers
        };

        const data = yield call(apiCall, 'get', config.credentials.url + '/contactType?_format=json&type=all', requestOptions)

        yield put({ type: "RESPONSE_CONTACT_TYPE", value: data })

    } catch (error) {
        console.log("SAGA MODEL ERROR", error)
    }
}


function* modules() {
    try {

        const headers = {
            'Content-Type': "application/json",
            'token': config.credentials.token
        };

        const requestOptions = {
            headers: headers
        };

        const data = yield call(apiCall, 'get', config.credentials.url + '/modules?_format=json', requestOptions)

        console.log("MODULES", data)

        yield put({ type: "RESPONSE_MODULES", value: data })

    } catch (error) {
        console.log("SAGA MODEL ERROR", error)
    }
}

function* pasoUno(actions) {

    const headers = {
        'Content-Type': "application/json",
        'token': config.credentials.token
    };



    const bodyRequest = {
        "module": actions.currentStep.modulo,
        "reason": actions.currentStep.motivo,
        "purchase_number": actions.currentStep.nropedido,
        "purchase_mail": actions.currentStep.email,
        "client":config.client.id
    }
    console.log("body request", bodyRequest)
    const requestOptions = {
        headers: headers,
        body: JSON.stringify(bodyRequest)
    };
    // return { "status": "SUCCESS", "request": { "module": 1, "reason": 2, "purchase_number": "VL-141414", "purchase_mail": "probando@prueba.com" }, "data": { "pax_name": "NOMBRE TITULAR RESERVA", "pax_surname": "APELLIDO TITULAR RESERVA", "contact_phone": "TELEFONO TITULAR RESERVA", "reservation_date": "FECHA EN QUE SE REALIZA LA RESERVA", "service_date": "FECHA EN QUE SE REALIZA EL VIAJE" } };

    return yield call(apiCall, 'post', config.credentials.url + '/validateForm/', requestOptions)
}

function* pasoDos(actions) {

    const headers = {
        'Content-Type': "application/json",
        'token': config.credentials.token
    };

    const bodyRequest = actions.currentStep


    const requestOptions = {
        headers: headers,
        body: JSON.stringify(bodyRequest)
    };

    const response = yield call(apiCall, 'post', config.credentials.url + '/sendContactForm/', requestOptions)
    return { "status": "SUCCESS", "data": response };

    // return yield call(apiCall, 'post', config.credentials.url + '/sendContactForm/', requestOptions)
}


function* form(actions) {
    try {
        let response = '';

        if (actions.currentNumberStep === 1)
            response = yield pasoUno(actions);


        if (actions.currentNumberStep === 2)
            response = yield pasoDos(actions);

        console.log("respuesta webservice", response)

        yield put({ type: "RESPONSE_FORM", infoStep: actions.currentStep, step: actions.currentNumberStep, response: response })

    } catch (error) {
        yield put({ type: "RESPONSE_FORM_ERROR", infoStep: actions.currentStep, step: actions.currentNumberStep })

    }
}
function* preload(actions) {
    yield put({ type: "PRELOAD", boolean: actions.boolean })
}

export function* getContactType() {
    yield takeLatest("GET_CONTACT_TYPE", contactType)
    yield takeLatest("GET_MODULES", modules)
}

export function* sendForm() {
    yield takeEvery("SEND_FORM", form)
}

export function* updatePreload() {
    yield takeEvery("UPDATE_PRELOAD", preload)
}

