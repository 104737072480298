import React, { Component, Fragment } from 'react';
import { Grid, Container, Paper } from '@material-ui/core';
import Header from "./Header";
import Footer from "./Footer";
import Theme from "../assets/Theme";
import config from ".././config/config.json";
import RegretFormConfirmation from "./layout/RegretFormConfirmation";


export default class LayoutThanksPage extends Component {
    render() {
        console.log(this.props.location.state.infoStep)
        console.log("TYPE FORM", this.props.location.state.typeForm)
        return (
            <Fragment>
                <Theme>
                    <div className={`wrapper`} styles={config.styles} >
                        <Header client={config.client} />
                        <div className="main">
                            <RegretFormConfirmation typeForm={this.props.location.state.typeForm} infoStep={this.props.location.state.infoStep} />
                        </div>
                        <Footer client={config.client} />
                    </div>

                </Theme>
            </Fragment>
        )
    }
}
