import { takeLatest, takeEvery, put, call } from "redux-saga/effects";
import apiCall from "../services"
import config from '../config/config.json';

function* form(actions) {


    const headers = {
        'Content-Type': "application/json",
        'token': config.credentials.token
    };

    const bodyRequest = actions.currentStep
    console.log("body", bodyRequest)

    const requestOptions = {
        headers: headers,
        body: JSON.stringify(bodyRequest)
    };

    const response = yield call(apiCall, 'post', config.credentials.url + '/sendContactForm/', requestOptions)
    console.log("llego a saga", response)

    yield put({ type: "RESPONSE_FORM_SALES", infoStep: actions.currentStep, step: actions.currentNumberStep, response: response })


}
function* preload(actions) {
    yield put({ type: "PRELOAD_", boolean: actions.boolean })
}

export function* sendFormSales() {
    yield takeEvery("SEND_FORM_SALES", form)
}

export function* updatePreloadSales() {
    // yield takeEvery("UPDATE_PRELOAD", preload)
}

