import React, { Component, Fragment } from 'react'
import { Grid, Paper, Typography, Icon, Container, Popover, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core'
import String from '../common/String';
import Email from '../common/Email';
import Number from '../common/Number';
import SimpleSelect from '../common/SimpleSelect';
import Image from '../layout/Image';
import { Alert, AlertTitle } from '@material-ui/lab';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";


const withRouterAndRef = (WrappedComponent) => {
    class InnerComponentWithRef extends React.Component {
        render() {
            const { forwardRef, ...rest } = this.props;
            return <WrappedComponent {...rest} ref={forwardRef} />;
        }
    }
    const ComponentWithRouter = withRouter(InnerComponentWithRef, { withRef: true });
    return React.forwardRef((props, ref) => {
        return <ComponentWithRouter {...props} forwardRef={ref} />;
    });
}



class RegretFormReason extends Component {
    constructor(props) {
        super(props);

        this.state = {
            motivo: '',
            tipoproducto: '',
            nropedido: '',
            email: '',
            modulo: '',
            responseForgot: false,
            anchorEl: 0,
            cliente: this.props.client,
            selectedTipoProducto: 'Aereo',
            selectModulo: '1',
            getProductos: null,
            getModulos: [
                { code: '1', description: 'VL' },
                { code: '2', description: 'GM' },
                { code: '3', description: 'AS' },
                { code: '4', description: 'AT' }
            ],
            getMotivos: [
                { code: '01', description: 'Motivo 01' },
                { code: '02', description: 'Motivo 02' },
                { code: '03', description: 'Motivo 03' },
                { code: '04', description: 'Motivo 04' },
                { code: '05', description: 'Motivo 05' }
            ],
            errors: {
                motivo: false,
                tipoproducto: false,
                email: false,
                nropedido: false
            },
            showErrorText: {
                motivo: false,
                email: false,
                tipoproducto: false,
                nropedido: false
            }
        }
    }
    UNSAFE_componentWillMount() {
        this.props.getContactType('fkKv5jehJSGWBcdxAoVB5TEaWGtMVAQVnZGoGnCA');
        this.props.getModules();
    }

    UNSAFE_componentWillReceiveProps(next_props) {
        /*{ id: '1', code: 'Aereo', description: 'Aereo', 'module': 'VL' }, */
        var temporaryObj = [];
        next_props.responseApiModulos.map((modules) => {
            temporaryObj.push({ id: modules.id, code: modules.module, description: modules.module, 'module': modules.code })
        })

        this.setState({ getProductos: temporaryObj })

    }



    handleSubmit = () => {

        let count = 0;
        let countValid = 0;
        let form = new Object();
        let response = true;

        {/*  if (this.state.motivo && this.state.motivo !== null) {
            count++;
            if (this.state.motivo.state.defaultValue !== '' && this.state.motivo.state.defaultValue !== null) {
                countValid++;
                form[this.state.motivo.props.name] = this.state.motivo.state.defaultValue;
            }
        }*/}
        if (this.state.tipoproducto && this.state.tipoproducto !== null) {
            count++;
            if (this.state.tipoproducto.state.defaultValue !== '' && this.state.tipoproducto.state.defaultValue !== null) {
                countValid++;
                form[this.state.tipoproducto.props.name] = this.state.tipoproducto.state.defaultValue;
            }
        }
        if (this.state.email && this.state.email !== null) {
            count++;
            if (this.state.email.state.defaultValue !== '') {
                countValid++;
                form[this.state.email.props.name] = this.state.email.state.defaultValue;
            }
        }
        if (this.state.nropedido && this.state.nropedido !== null) {
            count++;
            if (this.state.nropedido.state.defaultValue !== '') {
                countValid++;
                var prefijo = this.state.getProductos.filter((producto) => producto.code === this.state.selectedTipoProducto);
                form[this.state.nropedido.props.name] = prefijo[0].module + "-" + this.state.nropedido.state.defaultValue;
            }
        }
        form["modulo"] = this.state.selectModulo;
        form["tipoproducto"] = this.state.selectedTipoProducto;
        form["motivo"] = this.props.match.params.idInquiries

        this.setState({
            errors: {
                motivo: (this.state.motivo && this.state.motivo.state.defaultValue !== '' && this.state.motivo.state.errorText === '' && this.state.motivo.state.errorText === '') ? false : true,
                tipoproducto: (this.state.tipoproducto && this.state.tipoproducto.state.defaultValue !== '' && this.state.tipoproducto.state.errorText === '' && this.state.tipoproducto.state.errorText === '') ? false : true,
                nropedido: (this.state.nropedido && this.state.nropedido.state.defaultValue !== '' && this.state.nropedido.state.errorText === '') ? false : true,
                email: (this.state.email && this.state.email.state.defaultValue !== '' && this.state.email.state.errorText === '') ? false : true
            },
            showErrorText: {
                motivo: (this.state.motivo && this.state.motivo.state.defaultValue === '') ? true : false,
                tipoproducto: (this.state.tipoproducto && this.state.tipoproducto.state.defaultValue === '') ? true : false,
            }
        })

        if (response) {
            if (countValid !== count) {
                response = false;
                this.setState({ responseForgot: true })
            } else {
                this.setState({ responseForgot: false })
            }
        }

        return { 'status': response, 'data': form };
    }

    handleSelectProduct = (event) => {
        this.setState({ selectedTipoProducto: event.target.value })
        var modulo = this.state.getProductos.filter((producto) => producto.code === event.target.value);
        this.setState({ selectModulo: modulo[0].id })
    }

    render() {

        /* let url = this.props.location.search;
         let params = queryString.parse(url);
         console.log("params", params);*/
        console.log("this.props.match.params.idInquiries", this.props.match.params.idInquiries)

        const handlePopoverOpen = (event) => {
            this.setState({ anchorEl: event.currentTarget });
        };
        const handlePopoverClose = () => {
            this.setState({ anchorEl: null })
        };

        const responseContactType = this.props.responseContactType;
        let type = [];
        responseContactType.map((contactType) => {
            type.push({
                "code": contactType.id,
                "description": contactType.name
            })

        })

        const open = Boolean(this.state.anchorEl);
        return (
            <Fragment>
                <Container >

                    <Grid container spacing={2} >
                        <Grid item md={12}>
                            <div className="introStep">
                                <Typography variant="h1" className="headerTitle" color="primary">¿Cómo podemos ayudarte?</Typography>
                                <Typography variant="h4" className="headerParagraph paragraphMargin">Para poder brindarte una mejor asistencia, por favor seleccioná
                                    el motivo por el cual nos estás contactando.</Typography>
                            </div>
                        </Grid>
                        <Grid item md={12}>
                            <div className="stepHeader">
                                <Typography variant="h3" color="primary" className="headerTitle">1. Motivo de contacto</Typography>
                                <Typography variant="h4" className="headerSubtitle paragraphMargin">Ingresá los datos del titular de la reserva</Typography>
                            </div>
                        </Grid>
                    </Grid>
                </Container>
                <Container className="formBlock">
                    <Paper className="paper paper-form">
                        <Grid container spacing={2} >
                            <Grid item md={8} className="formContent">
                                <Grid container spacing={1}>
                                    <Grid item xs={12} md={12}>
                                        {!this.state.responseForgot === false && (
                                            <Alert severity="error">
                                                <Typography>Debe completar todos los campos.</Typography>
                                            </Alert>
                                        )}
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        {this.props.status === "ERROR" && (
                                            <Alert severity="error">
                                                <Typography>{this.props.responseWs.message}</Typography>
                                            </Alert>
                                        )}
                                    </Grid>
                                    {/*<Grid item xs={12} md={6}>
                                         <SimpleSelect
                                            ref={element => { this.state.motivo = element }}
                                            id={`motivo`}
                                            labelId={`motivo`}
                                            labelText="Seleccione un motivo:"
                                            name={`motivo`}
                                            defaultValue={''}
                                            optionsSelect={type}
                                            error={this.state.errors.motivo}
                                            classNameCustom={this.state.showErrorText.motivo ? 'invalidData' : 'validData'}
                                        />
                                    </Grid>*/}
                                    <Grid item xs={12} md={6}>
                                        <Email
                                            ref={element => { this.state.email = element }}
                                            id="email"
                                            label="Email"
                                            name="email"
                                            defaultValue={''}
                                            error={this.state.errors.email}
                                            autoComplete="email"
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <FormControl variant="outlined" fullWidth >

                                            {
                                                this.state.getProductos !== null ?
                                                    <Select
                                                        //  ref={element => { this.state.tipoproducto = element }}
                                                        labelId="tipoproducto"
                                                        id="tipoproducto"
                                                        defaultValue={"Aereo"}
                                                        onChange={this.handleSelectProduct}
                                                        label="Tipo de Producto"
                                                    >
                                                        {
                                                            this.state.getProductos.map((productos, indice) => {
                                                                return <MenuItem key={productos.code} value={productos.code}>{productos.description}</MenuItem>
                                                            })
                                                        }
                                                    </Select>
                                                    :

                                                    < p > cargando...</p>

                                            }
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <div className="contNroPedido">
                                            <div className="bloqLabel ">Nro. de pedido:</div>
                                            <div className="flexContainer">
                                                <div className="bloqSelect">
                                                    <FormControl variant="outlined" fullWidth >
                                                        {/* <SimpleSelect
                                                            ref={element => { this.state.modulo = element }}
                                                            id={`modulo`}
                                                            labelId={`modulo`}
                                                            disabled={true}
                                                            name={`modulo`}
                                                            defaultValue={this.state.selectModulo}
                                                            optionsSelect={this.state.getModulos}
                                                            error={this.state.errors.modulo}
                                                            classNameCustom={this.state.showErrorText.motivo ? 'invalidData' : 'validData'}
                                                        />*/}


                                                        <Select
                                                            inputRef={element => { this.state.modulo = element }}
                                                            // ref={element => { this.state.modulo = element }}
                                                            labelId="modulo"
                                                            id="modulo"
                                                            name="modulo"
                                                            disabled
                                                            value={this.state.selectModulo}
                                                            onChange={this.handleSelectProduct}
                                                            label="modulo"
                                                        >
                                                            {
                                                                this.state.getModulos.map((modulos, indice) => {
                                                                    return <MenuItem key={modulos.code} value={modulos.code}>{modulos.description}</MenuItem>
                                                                })
                                                            }
                                                        </Select>
                                                    </FormControl>

                                                </div>
                                                <div className="bloqGuion">
                                                    <span className="guion">-</span>
                                                </div>
                                                <div className="bloqInput">
                                                    <Number
                                                        ref={element => { this.state.nropedido = element }}
                                                        id={`nropedido`}
                                                        name={`nropedido`}
                                                        minLength={null}
                                                        maxLength={null}
                                                        defaultValue={''}
                                                        disabled={this.props.disabled ? this.props.disabled : false}
                                                        error={this.state.errors.nropedido}
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                <Typography
                                                    aria-owns={open ? 'mouse-over-popover' : undefined}
                                                    aria-haspopup="true"
                                                    onMouseEnter={handlePopoverOpen}
                                                    onMouseLeave={handlePopoverClose}
                                                    className="popoverButton"
                                                >
                                                    ¿Dónde encuentro mi número de pedido?
                                                </Typography>
                                                <Popover
                                                    id="mouse-over-popover"
                                                    style={{ pointerEvents: 'none' }}
                                                    // className={classes.popover}
                                                    // classes={{
                                                    // paper: classes.paper,
                                                    // }}
                                                    open={open}
                                                    anchorEl={this.state.anchorEl}
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'left',
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'left',
                                                    }}
                                                    onClose={handlePopoverClose}
                                                    disableRestoreFocus
                                                >
                                                    <div>
                                                        <Paper className="paperPopover">
                                                            <div className="popoverText">Número de compra</div>
                                                            <Image className="popoverImage" img={`${this.state.cliente.name}/dondeEncuentroPedido.png`} />
                                                            <div className="popoverText">El número de compra lo encontrarás en tu Voucher. Ej. VL-123456</div>
                                                        </Paper>
                                                    </div>
                                                </Popover>
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Container>
            </Fragment >
        )
    }
}

const mapStateToProps = state => {
    return state
};

const mapDispachToProps = dispatch => {
    return {
        getContactType: (access) => dispatch({ type: "GET_CONTACT_TYPE", access }),
        getModules: () => dispatch({ type: "GET_MODULES" }),
    };
};


export default withRouterAndRef(connect(mapStateToProps, mapDispachToProps, null,
    { forwardRef: true })(RegretFormReason))
{/*export default connect(mapStateToProps, mapDispachToProps, null,
    { forwardRef: true })(RegretFormReason)*/}


