import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import LayoutRegret from "./components/LayoutRegret";
import LayoutLandinForms from "./components/LayoutLandinForms";
import LayoutThanksPage from "./components/LayoutThanksPage";
import LayoutSalesForm from "./components/LayoutSalesForm";
import LayoutSalesThanksPage from "./components/LayoutSalesThanksPage";


import config from "./config/config.json";

class Routing extends Component {
    render() {
        return (
            <BrowserRouter>
                <Switch>
                    <Route path={"/formulario"} component={LayoutLandinForms} exact />
                    <Route path={"/formulario/consultas/:idInquiries"} component={() => <LayoutRegret typeForm={"consultas"} />} exact />
                    <Route path={"/formulario/canjes/:idInquiries"} component={() => <LayoutRegret typeForm={"canjes"} />} exact />
                    <Route path={"/formulario/ventas/:idInquiries"} component={LayoutSalesForm} exact />
                    <Route path={"/confirmacion"} component={LayoutThanksPage} exact />
                    <Route path={"/confirmacion-ventas"} component={LayoutSalesThanksPage} exact />

                </Switch>
            </BrowserRouter>
        );
    }
}

export default Routing;