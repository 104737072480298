import React, { Component, Fragment } from 'react'
import { Grid, Paper, Typography, Icon, FormControlLabel, Container } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert';
import String from '../common/String';
import Email from '../common/Email';
import Number from '../common/Number';
import SimpleSelect from '../common/SimpleSelect';
import DateValid from '../common/DateValid';

export default class RegretFormSalesConfirmation extends Component {

    constructor(props) {
        super(props);

    }

    render() {
        const pasoUno = this.props.infoStep.filter(e => e.step === 1)[0]
        /*const pasoDos = this.props.infoStep.filter(e => e.step === 2)[0]*/
        return (
            <Fragment>
                <Container maxWidth="xl" disableGutters={true} className="topInfoBrand">
                    <Grid container spacing={0}>
                        <Grid item sm={12}>
                            <Typography component="div"><Icon className="headerIcon">check_circle_outline</Icon></Typography>
                            <Typography component="div" variant="h1" className="headerTitle">Tu consulta fue enviada de manera exitosa.</Typography>
                            <Typography component="div" variant="h3" className="headerParagraph ">Gracias por contactarte con nosotros.</Typography>

                        </Grid>
                    </Grid>
                </Container>
                <br /> <br />
                <Container className="formBlock">
                    <Paper className="paper paper-form">
                        <Grid container spacing={2} >
                            <Grid item md={12} className="formContent">
                                <Grid container spacing={1}>
                                    <Grid item md={12} >
                                        <Typography component="span" variant="h4">Tu código de pedido es:    </Typography> <Typography component="span" variant="h1"> {pasoUno.responseWs.number_claim} </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Container>
                <Container className="formBlock">
                    <Paper className="paper paper-form">
                        <Grid container spacing={2} >
                            <Grid item md={12} className="formContent">
                                <Grid container spacing={1}>

                                    <Grid item md={12}>
                                        <Typography variant="h4">Datos del titular</Typography>
                                    </Grid>
                                    <Grid item sm={3}>
                                        <div className="dataInfo">
                                            <div className="dataTitle">Nombre y Apellido</div>
                                            <div className="dataDescription">{pasoUno.info.pax_name} {pasoUno.info.pax_surname}</div>
                                        </div>
                                    </Grid>
                                    <Grid item sm={3}>
                                        <div className="dataInfo">
                                            <div className="dataTitle">Teléfono de contacto</div>
                                            <div className="dataDescription">{pasoUno.info.contact_phone}</div>
                                        </div>
                                    </Grid>
                                    <Grid item sm={3}>
                                        <div className="dataInfo">
                                            <div className="dataTitle">Horario de contacto</div>
                                            <div className="dataDescription">{pasoUno.info.contact_hour}</div>
                                        </div>
                                    </Grid>
                                    <Grid item sm={12}>
                                        <div className="dataInfo">
                                            <div className="dataTitle">Tu consulta</div>
                                            <div className="dataDescription"> {pasoUno.info.observations}</div>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>

                    <br /> <br />

                    <Paper className="paper paper-form">
                        <Grid container spacing={2} >
                            <Grid item md={12} className="formContent">
                                <Grid container spacing={1}>
                                    <Grid item md={12} className="alertInfo">
                                        <Alert severity="info"> <Typography component="span" variant="h5"> Podemos llegar a tener demoras en contactarte por el gran volumen de consultas que estamos recibiendo.
                                    Recordá que podés realizar tus propias búsquedas en <a target="_blank" href={this.props.client.siteUrl}>{this.props.client.siteUrl}</a></Typography></Alert>

                                        {/* <div className="dataDescription"> Podemos llegar a tener demoras en contactarte por el gran volumen de consultas que estamos recibiendo.<br />
Recordá que podés realizar tus propias búsquedas en <a target="_blank" href={this.props.client.siteUrl}>{this.props.client.siteUrl}</a></div> */}

                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Container>
            </Fragment>
        )
    }
}
