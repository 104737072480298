import React, { Component } from 'react'
import { Grid, Paper, Typography, Icon, FormControlLabel, Container } from '@material-ui/core'

export default class TopInfoBrand extends Component {
    render() {
        return (
            <Container maxWidth="xl" disableGutters={true} className="topInfoBrand">
                <Grid container spacing={0}>
                    <Grid item sm={12}>
                        <Typography variant="h1" className="headerTitle">Centro de Atención al Cliente</Typography>
                        <Typography variant="h3" className="headerParagraph">Si necesitás realizar una consulta o gestión sobre tu reserva,<br /> completá el formulario y nos pondremos en contacto lo antes posible.</Typography>

                    </Grid>
                </Grid>
            </Container>
        )
    }
}
