import React from 'react'
import { Fragment } from 'react'
import { Typography, Dialog, DialogContent, Button, Icon, Grid, Container } from '@material-ui/core';
import Image from '../layout/Image';



export default function PreloadingModal(props) {

    return (
        <Fragment>
				<Dialog color="primary" maxWidth={'sm'} fullWidth={true} open={props.open}>
					<DialogContent dividers >
						<Container maxWidth={"sm"} align="center" >
							<Typography variant="h5">
								{props.title}
							</Typography>
							{/* <p><Image className="tableImgProvider" src={`/images/banners/preload.gif`} alt={"cargando..."} /></p> */}
							<p> <Image img={props.img} className="preloadImage" /></p>
						</Container>
					</DialogContent>
				</Dialog>
        </Fragment>
    )
}
